import React, { useRef, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import '../../styles/mainContent/serviceDetails.css'; // Ensure this CSS file is created
import Navigation from '../../components/Navigation';
import CustomCarousel from './CustomCarousel'; // Import the new CustomCarousel component
import FooterSection from '../FooterSection';
import logo from '../../images/Fraser.png';

// Import images for each service
import StandardImage1 from '../../images/standardImages/StandardPhotography1.jpg';
import StandardImage2 from '../../images/standardImages/StandardPhotography2.jpg';
import DroneImage1 from '../../images/droneImages/DronePhotography1.jpg';
import DroneImage2 from '../../images/droneImages/DronePhotography2.jpg';
import DroneImage3 from '../../images/droneImages/DronePhotography3.jpg';
import TwoDImage1 from '../../images/2dImages/2dPhotography1.jpg';
import TwoDImage2 from '../../images/2dImages/2dPhotography2.jpg';
import ThreeDImage1 from '../../images/3dImages/3dphotography1.jpg';
import ThreeDImage2 from '../../images/3dImages/3dPhotography2.jpg';
// import walkthrough1 from '../../images/socialMediaWalkthroughVid/social_media_walkthrough_video.mp4';
import DroneImgVideo from '../../images/droneImages/drone-shot-website-1.mp4';
import stageVideo2 from '../../images/stagedVid/real-vs-staged-website-1.mp4';
import stageVideo1 from '../../images/landscape-video/mls-video-website.mp4';
import walkthrough1 from '../../images/socialMediaWalkthroughVid/social-media-website-1.mp4';

// Define services array, similar to the one used in services.js
const services = [
    { 
        serviceName: 'STANDARD (INTERIOR & EXTERIOR) PHOTOGRAPHY', 
        urlName: 'standard-photography', 
        description: "Our standard photography service captures high-quality images for both the interior and exterior of your property, providing potential buyers with a comprehensive visual experience.", 
        images: [StandardImage1, StandardImage2] 
    },
    { 
        serviceName: 'DRONE AERIAL PHOTOGRAPHY', 
        urlName: 'drone-aerial-photography', 
        description: "Capture stunning aerial views of your property with our drone photography service, perfect for showcasing larger properties and unique landscapes.", 
        images: [DroneImage1, DroneImage2, DroneImage3] 
    },
    { 
        serviceName: '2D FLOOR PLAN', 
        urlName: '2d-floor-plan', 
        description: "Our 2D floor plan service provides accurate and clear layouts of your property, helping buyers visualize the space.", 
        images: [TwoDImage1, TwoDImage2] 
    },
    { 
        serviceName: '3D FLOOR PLAN', 
        urlName: '3d-floor-plan', 
        description: "We offer detailed 3D floor plans that give potential buyers a realistic view of your property’s layout, helping them explore the space from a different perspective.", 
        images: [ThreeDImage1, ThreeDImage2] 
    },
    { 
        serviceName: 'SOCIAL MEDIA VIDEO WALKTHROUGH (PORTRAIT)', 
        urlName: 'social-media-video', 
        description: "Our social media video walkthroughs are designed specifically for platforms like Instagram and Facebook, giving your listing maximum exposure with visually engaging content.", 
        images: [walkthrough1] 
    },
    { 
        serviceName: 'MLS VIDEO WALKTHROUGH (LANDSCAPE)', 
        urlName: 'mls-video', 
        description: "We create professional MLS video walkthroughs, showcasing your property in a high-definition, landscape-oriented video, perfect for real estate listings.", 
        images: [stageVideo1] 
    },
    { 
        serviceName: 'DRONE VIDEO', 
        urlName: 'drone-video', 
        description: "Our drone video service captures beautiful, sweeping aerial footage, providing a unique perspective of your property.", 
        images: [DroneImgVideo] 
    },
    { 
        serviceName: 'STAGED VIDEO (LANDSCAPE OR PORTRAIT)', 
        urlName: 'staged-video', 
        description: "Staged videos highlight the best features of your property, offering a professionally styled walkthrough in either landscape or portrait format.", 
        images: [stageVideo2] 
    }
];

function ServiceDetails() {
    const { urlName } = useParams(); // Get URL param to identify the service
    const [isInView, setIsInView] = useState(false);
    const divRef = useRef(null);
    const navigate = useNavigate();
    
    // Find the matching service by urlName
    const service = services.find(service => service.urlName === urlName);

    const handleScroll = (section, url) => {
        navigate(url);
        setTimeout(() => {
            const sectionElement = document.getElementById(section);
            if (sectionElement) {
                sectionElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    // Scroll to top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0); // This ensures that the page loads from the top
    }, []);

    useEffect(() => {
        const currentRef = divRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsInView(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    if (!service) {
        return <p>Service not found</p>;
    }

    // Convert service images to the expected format (with src and alt properties)
    const carouselImages = service.images.map((img, index) => ({
        src: img,
        alt: `${service.serviceName} - image ${index + 1}`
    }));

    return (
        <main id="serviceDetails" className="service-details-container">
            {/* Header section with logo, menu, and carousel */}
            <header className="pageTitle">
                <div className="header-content">
                    <div className="logoTitle header-logo">
                        <button 
                            onClick={() => handleScroll('home', '/')}
                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                            <img src={logo} alt="Logo" className="Logo" />
                        </button>
                    </div>
                    <Navigation isFooter={false} />
                </div>

                {/* Carousel inside the header section */}
                <div className="service-details-carousel-container">
                    <CustomCarousel images={carouselImages} serviceName={service.serviceName} />
                </div>
            </header>

            {/* Service details content section */}
            <section className="service-details-content-section">
                <div className="service-details-content">
                    <div ref={divRef} className={`service-details-description ${isInView ? 'in-view' : ''}`}>
                        <h2 className="service-details-h2-title serviceName">{service.serviceName}</h2>
                        <p className="service-details-description-para">{service.description}</p>
                    </div>
                </div>
            </section>

            <FooterSection />
        </main>
    );
}

export default ServiceDetails;
