import React from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom'; // useNavigate for routing
import '../../styles/mainContent/services.css';
import StandardImage1 from '../../images/standardImages/StandardPhotography1.jpg';
// import StandardImage2 from '../../images/standardImages/StandardPhotography2.jpg';
import DroneImage1 from '../../images/droneImages/DronePhotography1.jpg';
// import DroneImage2 from '../../images/droneImages/DronePhotography2.jpg';
// import DroneImage3 from '../../images/droneImages/DronePhotography3.jpg';
import DroneImgVideo from '../../images/droneImages/drone-shot-website-1.mp4';
// import DroneVideoGif from '../../images/droneImages/DroneVideo2.gif';
// import TwoDImage1 from '../../images/2dImages/2dPhotography1.jpg';
import TwoDImage2 from '../../images/2dImages/2dPhotography2.jpg';
// import ThreeDImage1 from '../../images/3dImages/3dphotography1.jpg';
import ThreeDImage2 from '../../images/3dImages/3dPhotography2.jpg';
import stageVideo2 from '../../images/stagedVid/real-vs-staged-website-1.mp4';
import stageVideo1 from '../../images/landscape-video/mls-video-website.mp4';
import walkthrough1 from '../../images/socialMediaWalkthroughVid/social-media-website-1.mp4';
import backgroundImg from '../../images/service-background.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LazyLoad from 'react-lazyload';

// Updated services array with URL-friendly names
const services = [
    { serviceName: 'STANDARD PHOTOGRAPHY (INTERIOR & EXTERIOR)', urlName: 'standard-photography', src: [StandardImage1], height: "250px", type: "image/gifs" },
    { serviceName: 'DRONE AERIAL PHOTOGRAPHY', urlName: 'drone-aerial-photography', src: [DroneImage1], height: "250px", type: "image/gifs" },
    { serviceName: '2D FLOOR PLAN', urlName: '2d-floor-plan', src: [TwoDImage2], height: "250px", type: "image/gifs" },
    { serviceName: '3D FLOOR PLAN', urlName: '3d-floor-plan', src: [ThreeDImage2], height: "250px", type: "image/gifs" },
    { serviceName: 'SOCIAL MEDIA VIDEO WALKTHROUGH (PORTRAIT)', urlName: 'social-media-video', src: [walkthrough1], height: "250px", type: "video" },
    { serviceName: 'MLS VIDEO WALKTHROUGH (LANDSCAPE)', urlName: 'mls-video', src: [stageVideo1], height: "250px", type: "video" },
    { serviceName: 'DRONE VIDEO', urlName: 'drone-video', src: [DroneImgVideo], height: "250px", type: "video" },
    { serviceName: 'STAGED VIDEO (LANDSCAPE OR PORTRAIT)', urlName: 'staged-video', src: [stageVideo2], height: "250px", type: "video" }
];

const CustomArrowPrev = (onClickHandler, hasPrev, label) =>
    hasPrev && (
        <button type="button" onClick={onClickHandler} title={label} className="custom-arrow custom-arrow-prev">
            &lt;
        </button>
    );
const CustomArrowNext = (onClickHandler, hasNext, label) =>
    hasNext && (
        <button type="button" onClick={onClickHandler} title={label} className="custom-arrow custom-arrow-next">
            &gt;
        </button>
    );

function Services() {
    const navigate = useNavigate(); // Using useNavigate for routing

    const handleLearnMore = (urlName) => {
        // Fix: Ensure we use a template string for correct URL construction
        navigate(`/service-details/${urlName}`);
    };

    return (
        <section id="services" className="services">
            <img src={backgroundImg} className="services-background-img" alt="backgroundImg"/>
            <h1 className="h1-title">OUR SERVICES</h1>
            <div className='border-line-div-services'>
                <span className='border-line-services'/>
            </div>
            <div className="service-grid">
                {services.map((service, index) => (
                    <div className="service" key={index}>
                        <Carousel infiniteLoop showThumbs={false} renderArrowPrev={CustomArrowPrev} renderArrowNext={CustomArrowNext} showStatus={true} showIndicators={false} className='carouselImgs'>
                            {service.src.map((fileSrc, imgIndex) => (
                                <LazyLoad
                                    key={imgIndex}
                                    height={250}
                                    offset={100}
                                    once
                                    placeholder={<div style={{ height: service.height, backgroundColor: '#ccc' }} />}
                                >
                                    {service.type === 'image/gifs' ? (
                                        <div className="image-div">
                                            <img
                                                src={fileSrc}
                                                alt={`${imgIndex + 1} of ${service.serviceName}`}
                                                className="service-img"
                                                style={{ height: service.height }}
                                                loading="lazy"
                                            />
                                        </div>
                                    ) : (
                                        <div className="video-div">
                                            <ReactPlayer
                                                className="video"
                                                url={fileSrc}
                                                playing
                                                muted
                                                loop
                                                height={service.height}
                                                width="100%"
                                                controls={false}
                                                loading="lazy"
                                            />
                                        </div>
                                    )}
                                </LazyLoad>
                            ))}
                        </Carousel>
                        <div className='details-cont'>
                            <h2 className='service-heading'>{service.serviceName}</h2>
                            <button className="lrn-more-btn" onClick={() => handleLearnMore(service.urlName)}>Learn More</button>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default React.memo(Services);
